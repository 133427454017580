import React from "react";
import { Link } from "react-router-dom";

const Home = () => (
  <div className="home-container">
    <header className="app-header">
      <h1>SCM MCQ</h1>
    </header>
    <main className="main-content">
      <h2>Welcome to Supply Chain Management MCQ</h2>
      <p>
        Test your knowledge of supply chain management concepts with our
        multiple-choice quizzes.
      </p>
      <div className="quiz-options">
        <Link to="/quiz/10" className="quiz-button">
          <span className="icon">🚀</span>
          <span>Quick: 10 Questions</span>
        </Link>
        <Link to="/quiz/20" className="quiz-button">
          <span className="icon">📚</span>
          <span>Quiz: 20 Questions</span>
        </Link>
        <Link to="/quiz/50" className="quiz-button">
          <span className="icon">🧠</span>
          <span>Test: 50 Questions</span>
        </Link>
        <Link to="/quiz/100" className="quiz-button">
          <span className="icon">🏆</span>
          <span>Exam: 100 Questions</span>
        </Link>
      </div>
    </main>
    <footer className="app-footer">
      <p>&copy; 2024 SCM MCQ. All rights reserved.</p>
    </footer>
  </div>
);

export default Home;
