import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./components/Home";
import Quiz from "./components/Quiz";
import Results from "./components/Results";
import "./App.css";

const App = () => (
  <Router>
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/quiz/:questionCount" element={<Quiz />} />
      <Route path="/results" element={<Results />} />
    </Routes>
  </Router>
);

export default App;
