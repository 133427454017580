import React from "react";
import { useLocation, Link } from "react-router-dom";

const Results = () => {
  const location = useLocation();
  const { score, total } = location.state || { score: 0, total: 0 };
  const percentage = ((score / total) * 100).toFixed(2);

  const getPerformanceMessage = () => {
    if (percentage >= 80) return "Excellent job!";
    if (percentage >= 60) return "Good work!";
    if (percentage >= 40) return "Nice effort!";
    return "Keep practicing!";
  };

  return (
    <div className="results-container">
      <h2>Quiz Results</h2>
      <div className="results-card">
        <div className="score-circle">
          <span className="score-text">{score}</span>
          <span className="total-text">/ {total}</span>
        </div>
        <p className="percentage">{percentage}%</p>
        <p className="performance-message">{getPerformanceMessage()}</p>
      </div>
      <Link to="/" className="home-button">
        Back to Home
      </Link>
    </div>
  );
};

export default Results;
