import React, { useState, useEffect } from "react";
import { useParams, useNavigate, Link } from "react-router-dom";

const Quiz = () => {
  const { questionCount } = useParams();
  const navigate = useNavigate();
  const [questions, setQuestions] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [score, setScore] = useState(0);
  const [showExplanation, setShowExplanation] = useState(false);
  const [selectedAnswer, setSelectedAnswer] = useState(null);
  const [error, setError] = useState(null);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    fetchQuestions(parseInt(questionCount));
  }, [questionCount]);

  const fetchQuestions = async (count) => {
    try {
      const response = await fetch("/questions.json");
      if (!response.ok) {
        throw new Error("Failed to fetch questions");
      }
      const allQuestions = await response.json();
      const shuffled = allQuestions.sort(() => 0.5 - Math.random());
      setQuestions(shuffled.slice(0, count));
    } catch (error) {
      console.error("Error fetching questions:", error);
      setError("Failed to load questions. Please try again later.");
    }
  };

  const handleAnswer = (selected) => {
    setSelectedOption(selected);
    setSelectedAnswer(selected);
    setShowExplanation(true);
    if (selected === questions[currentQuestionIndex].answer) {
      setScore(score + 1);
    }
  };

  const nextQuestion = () => {
    setShowExplanation(false);
    setSelectedAnswer(null);
    setSelectedOption(null);
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      navigate("/results", { state: { score, total: questions.length } });
    }
  };

  if (error)
    return (
      <div className="container">
        <p>{error}</p>
      </div>
    );
  if (questions.length === 0)
    return (
      <div className="container">
        <p>Loading...</p>
      </div>
    );

  const currentQuestion = questions[currentQuestionIndex];

  return (
    <div className="container">
      <div className="quiz-header">
        <h2>
          Question {currentQuestionIndex + 1} of {questions.length}
        </h2>
        <Link to="/" className="quit-button">
          Quit Quiz
        </Link>
      </div>
      <div className="question-container">
        <p className="question-text">{currentQuestion.question}</p>
        <div className="options-container">
          {["A", "B", "C", "D"].map((option) => (
            <button
              key={option}
              className={`option-button ${
                selectedOption === `Option${option}` ? "selected" : ""
              }`}
              onClick={() => handleAnswer(`Option${option}`)}
              disabled={showExplanation}
            >
              <span className="option-label">{option}</span>
              <span className="option-text">
                {currentQuestion[`option${option}`]}
              </span>
            </button>
          ))}
        </div>
      </div>
      {showExplanation && (
        <div className="explanation">
          <p
            className={
              selectedAnswer === currentQuestion.answer
                ? "correct"
                : "incorrect"
            }
          >
            {selectedAnswer === currentQuestion.answer
              ? "Correct!"
              : "Incorrect."}
          </p>
          <p>{currentQuestion[`explanation${selectedAnswer.slice(-1)}`]}</p>
          <button onClick={nextQuestion} className="next-button">
            {currentQuestionIndex < questions.length - 1
              ? "Next Question"
              : "Finish Quiz"}
          </button>
        </div>
      )}
    </div>
  );
};

export default Quiz;
